import { writable, derived } from 'svelte/store';
import Browser from '../routes/Browser.svelte';
import Email from '../routes/Email.svelte';
import MapComponent from '../routes/Map.svelte';
import Profile from '../routes/Profile.svelte';
import Spotify from '../routes/Spotify.svelte';
import Twitter from '../routes/Twitter.svelte';
import Messaging from '../routes/Messaging.svelte';
import Terminal from '../routes/Terminal.svelte';
import Gitweb from '../routes/Gitweb.svelte';
import CodeEditor from '../routes/CodeEditor.svelte';
import ToDo from '../routes/ToDo.svelte';

const { subscribe, update } = writable([
  {
    route: '/browser',
    title: 'Browser',
    component: Browser,
    size: {
      left: 52,
      top: 52,
      minWidth: 520,
      minHeight: 520,
      width: 880,
      height: 520,
      maximized: true,
    },
    image: '/browser.svg',
    state: 'CLOSED',
    pinned: true,
  },
  {
    route: '/code-editor',
    title: 'Code Editor',
    component: CodeEditor,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/code-editor.svg',
    state: 'CLOSED',
  },
  {
    route: '/email',
    title: 'Email',
    component: Email,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/email.svg',
    state: 'CLOSED',
  },
  {
    route: '/map',
    title: 'Map',
    component: MapComponent,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/map.svg',
    state: 'CLOSED',
  },
  {
    route: '/gitweb',
    title: 'Gitweb',
    component: Gitweb,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/gitweb.svg',
    state: 'CLOSED',
  },
  {
    route: '/profile',
    title: 'Profile',
    component: Profile,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/linkedin.svg',
    state: 'CLOSED',
  },
  {
    route: '/spotify',
    title: 'Spotify',
    component: Spotify,
    size: { left: 52, top: 52, minWidth: 360, minHeight: 520, width: 360, height: 520 },
    image: '/spotify.svg',
    state: 'CLOSED',
  },
  {
    route: '/messaging',
    title: 'Messaging',
    component: Messaging,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/messaging.svg',
    state: 'CLOSED',
    pinned: true,
  },
  {
    route: '/terminal',
    title: 'Terminal',
    component: Terminal,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/terminal.svg',
    state: 'CLOSED',
  },
  {
    route: '/to-do',
    title: 'To do',
    component: ToDo,
    size: { left: 52, top: 52, minWidth: 520, minHeight: 520, width: 880, height: 520 },
    image: '/to-do.svg',
    state: 'CLOSED',
  },
  {
    route: '/twitter',
    title: 'Twitter',
    component: Twitter,
    size: { left: 52, top: 52, minWidth: 320, minHeight: 520, width: 320, height: 520 },
    image: '/twitter.svg',
    state: 'CLOSED',
  },
]);

export const routes = {
  subscribe,
  open: (routeToOpen) =>
    update(($routes) => {
      const routeToOpenObj = $routes.find(({ route }) => route === routeToOpen);

      if (
        !routeToOpenObj ||
        (routeToOpenObj.state === 'OPENED' && routeToOpenObj.order === $routes.length)
      ) {
        return $routes;
      }

      routeToOpenObj.state = 'OPENED';
      routeToOpenObj.order = $routes.length + 1;

      return $routes.map((routeObj) =>
        Object.assign(routeObj, {
          order: routeObj.order ? routeObj.order - 1 : routeObj.order,
        }),
      );
    }),
  close: (routeToClose) =>
    update(($routes) => {
      const routeToCloseObj = $routes.find(({ route }) => route === routeToClose);

      if (!routeToCloseObj || routeToCloseObj.state === 'CLOSED') {
        return $routes;
      }

      routeToCloseObj.state = 'CLOSED';
      const currentorder = routeToCloseObj.order;
      routeToCloseObj.order = undefined;

      if (currentorder !== $routes.length) {
        return $routes;
      }

      return $routes.map((routeObj) =>
        Object.assign(routeObj, {
          order:
            routeObj.route !== routeToClose && routeObj.order ? routeObj.order + 1 : routeObj.order,
        }),
      );
    }),
  minimize: (routeToMinimize) =>
    update(($routes) => {
      const routeObj = $routes.find(({ route }) => route === routeToMinimize);

      routeObj.state = 'MINIMIZED';
      routeObj.order = undefined;

      return $routes;
    }),
  minimizeAll: () =>
    update(($routes) => {
      $routes.forEach((routeObj) => {
        if (routeObj.state !== 'OPENED') {
          return;
        }
        Object.apply(routeObj, {
          state: 'MINIMIZED',
          order: undefined,
        });
      });
      return $routes;
    }),
};

export const currentRoute = derived(routes, ($routes) =>
  $routes.find(({ order }) => $routes.length === order),
);
