<script>
  import { XIcon, MinusIcon, Maximize2Icon } from 'svelte-feather-icons';
  import { getContext } from 'svelte';

  const windowHandler = getContext('window');
  const routeObj = windowHandler.getRoute();
</script>

<style lang="postcss">
  .topbar {
    @apply flex text-sm select-none h-full;
    min-height: 2rem;
    opacity: 0.88;
    backdrop-filter: blur(5px);
  }
  .topbar__actions {
    @apply flex;
  }
  .topbar__actions > button {
    @apply h-full w-12 flex font-bold
      justify-center items-center text-2xl font-thin leading-none cursor-default;
  }
  .topbar__actions > button:hover {
    @apply text-gray-800 bg-gray-400;
  }

  .topbar__actions > button:last-child:hover {
    @apply text-red-200 bg-red-700;
  }

  :global(.topbar__actions__icon) {
    @apply w-5 h-5;
  }
</style>

<div class="topbar {$$props.class || ''}" style={$$props.style || ''}>
  <div class="flex" style="width: calc(100% - 9rem)">
    <slot name="menu" />
    <div
      draggable="true"
      on:dragstart={(e) => {
        e.dataTransfer.dropEffect = 'move';
        windowHandler.startResizing({ move: true, x: e.x, y: e.y }, e);
      }}
      on:dragend={windowHandler.stopResizing}
      on:dblclick={windowHandler.toggleMaximize}
      class="flex-grow flex items-center px-4">
      <slot />
    </div>
  </div>
  <div class="topbar__actions">
    <button on:click|stopPropagation={windowHandler.minimize}>
      <MinusIcon class="topbar__actions__icon" />
    </button>
    <button on:click={windowHandler.toggleMaximize}>
      <Maximize2Icon class="topbar__actions__icon" />
    </button>
    <button on:click={windowHandler.close}>
      <XIcon class="topbar__actions__icon" />
    </button>
  </div>
</div>
