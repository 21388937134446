<script>
  import { setContext } from 'svelte';
  import { routes } from '../stores/routes';

  export let routeObj;

  const size = { ...routeObj.size };

  let resizing;

  function drag(e) {
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy';

    if (resizing.left) {
      size.width = size.width + size.left - e.x;
      size.left = e.x;
    } else if (resizing.right) {
      size.width = e.x - size.left;
    }

    if (resizing.top) {
      size.height = size.height + size.top - e.y;
      size.top = e.y;
    } else if (resizing.bottom) {
      size.height = e.y - size.top;
    }

    if (resizing.move) {
      size.left = e.x + resizing.x;
      size.top = e.y + resizing.y;
    }
  }

  export function startResizing(obj, e) {
    e.dataTransfer.setData('text', '');
    e.dataTransfer.effectAllowed = 'copy';
    if (size.maximized) {
      size.maximized = false;
      size.top = 0;

      size.left = e.x - (e.x * size.width) / document.getElementById('desktop').offsetWidth;
    }
    if (obj.x) {
      obj.x = size.left - obj.x;
    }
    if (obj.y) {
      obj.y = size.top - obj.y;
    }
    resizing = obj;

    document.getElementById('desktop').addEventListener('dragover', drag);
  }

  export function stopResizing() {
    document.getElementById('desktop').removeEventListener('dragover', drag);
    resizing = undefined;
  }

  export function toggleMaximize() {
    size.maximized = !size.maximized;
  }

  export function minimize() {
    routes.minimize(routeObj.route);
  }

  export function close() {
    routes.close(routeObj.route);
  }

  setContext('window', {
    startResizing,
    stopResizing,
    toggleMaximize,
    minimize,
    close,
    getRoute: () => routeObj,
  });
</script>

<style lang="postcss">
  .window {
    @apply fixed overflow-auto shadow-lg bg-white flex flex-col;
  }

  .hot-spot {
    @apply w-2 h-2 absolute;
  }

  .hot-spot__left {
    @apply h-full top-0 left-0;
    cursor: ew-resize;
  }

  .hot-spot__top {
    @apply w-full top-0 left-0;
    cursor: ns-resize;
  }

  .hot-spot__right {
    @apply h-full top-0 right-0;
    cursor: ew-resize;
  }

  .hot-spot__bottom {
    @apply w-full bottom-0 left-0;
    cursor: ns-resize;
  }

  .hot-spot__top-left {
    @apply top-0 left-0;
    cursor: nwse-resize;
  }
  .hot-spot__top-right {
    @apply top-0 right-0;
    cursor: nesw-resize;
  }
  .hot-spot__bottom-left {
    @apply bottom-0 left-0;
    cursor: nesw-resize;
  }
  .hot-spot__bottom-right {
    @apply bottom-0 right-0;
    cursor: nwse-resize;
  }

  .maximized {
    width: 100% !important;
    height: calc(100% - 3rem) !important;
    top: 0 !important;
    left: 0 !important;
  }

  @media (max-width: 480px) {
    .window {
      width: 100% !important;
      min-width: 100% !important;
      height: 100% !important;
      min-height: 100% !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
</style>

<div
  class="window"
  class:maximized={!!size.maximized}
  style="left: {size.left}px; top: {size.top}px; width: {size.width}px; height: {size.height}px;
  min-width: {size.minWidth}px; min-height: {size.minHeight}px;"
  on:click={() => routes.open(routeObj.route)}>
  <svelte:component this={routeObj.component} />

  {#if !size.maximized}
    <div
      class="hot-spot hot-spot__left"
      draggable="true"
      on:dragstart={(e) => startResizing({ left: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__top"
      draggable="true"
      on:dragstart={(e) => startResizing({ top: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__right"
      draggable="true"
      on:dragstart={(e) => startResizing({ right: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__bottom"
      draggable="true"
      on:dragstart={(e) => startResizing({ bottom: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__top-left"
      draggable="true"
      on:dragstart={(e) => startResizing({ left: true, top: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__top-right"
      draggable="true"
      on:dragstart={(e) => startResizing({ right: true, top: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__bottom-right"
      draggable="true"
      on:dragstart={(e) => startResizing({ right: true, bottom: true }, e)}
      on:dragend={stopResizing} />
    <div
      class="hot-spot hot-spot__bottom-left"
      draggable="true"
      on:dragstart={(e) => startResizing({ left: true, bottom: true }, e)}
      on:dragend={stopResizing} />
  {/if}
</div>
