<script>
  import { onMount, onDestroy, getContext } from 'svelte';

  let time = new Date();
  let timeChangeInternal;

  const imageStates = {
    default: {
      image: '/avatar-lines.svg',
      welcomeText: 'Click on the screen to get in.',
    },
    onHover: {
      image: '/avatar-2.svg',
      welcomeText: "Where do you think you're going?",
    },
  };

  let currentImageState = 'default';

  const { setLogin } = getContext('appState');

  onMount(() => {
    timeChangeInternal = setInterval(() => {
      time = new Date();
    }, 5000);
  });

  onDestroy(() => {
    clearInterval(timeChangeInternal);
  });
</script>

<style lang="postcss">
  .welcome {
    @apply w-full h-full flex items-center justify-around bg-black bg-cover bg-no-repeat;
    background-image: url('/background.png');
    background-position: center bottom;
  }
</style>

<div class="welcome" on:click={() => setLogin(true)}>
  <div class="flex flex-col items-center text-white p-4 rounded">
    <img
      src={imageStates[currentImageState].image}
      alt="avatar"
      class="h-64 w-64 rounded-full bg-white mx-auto mb-4"
      style="--bg-opacity: 0.4"
      on:mouseenter={(e) => {
        currentImageState = 'onHover';
      }}
      on:mouseleave={(e) => {
        currentImageState = 'default';
      }} />
    <span class="text-2xl tracking-widest">Divyun Raje Vaid</span>
    <button class="px-4 mt-4 py-3 rounded text-center w-48 tracking-wide cursor-auto">
      {imageStates[currentImageState].welcomeText}
    </button>
  </div>

  <div class="flex flex-col justify-end items-center text-white p-8 rounded">
    <span class="text-6xl">
      {time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
    </span>
    Welcome to Divyun's Desktop.
  </div>

</div>
