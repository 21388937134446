<script>
  import TopBar from '../components/TopBar.svelte';

  const components = {
    Desktop: '/shield-logo.svg',
    Browser: '/browser.svg',
    'Code Editor': '/code-editor.svg',
    Email: '/email.svg',
    Gitweb: '/gitweb.svg',
    Spotify: '/spotify.svg',
    Messaging: '/messaging.svg',
    Terminal: '/terminal.svg',
    'To do': '/to-do.svg',
    Twitter: '/twitter.svg',
  };

  const tasks = [
    {
      name: 'Implement todo-list',
      component: 'To do',
      status: 'In Progress',
      type: 'FEATURE',
      description: 'haha',
      priorty: 9,
    },
    {
      name: 'Fix the address bar',
      component: 'Browser',
      status: 'To do',
      type: 'FEATURE',
      priorty: 5,
    },
    {
      name: 'Implement email',
      component: 'Email',
      status: 'To do',
      type: 'FEATURE',
      priorty: 7,
    },
    {
      name: 'Maximize on Double click',
      component: 'Top bar',
      status: 'To do',
      type: 'BUG',
      priorty: 9,
    },
  ];

  const colors = [
    'rgba(255,255,255,1)',
    'rgba(99,179,237,1)',
    'rgba(0,75,150,1)',
    'rgba(0,150,75,1)',
    'rgba(47,133,90,1)',
  ];
</script>

<style lang="postcss">
  .code-editor {
    @apply flex flex-col shadow-lg h-full w-full bg-gray-200;
  }

  .code-editor > div {
    @apply bg-gray-200;
  }

  .sidebar > div {
    filter: grayscale(20%);
    opacity: 0.79;
    transform: scale(0.916);
    transition: 250ms;
  }

  .sidebar > div:hover {
    filter: unset;
    opacity: 1;
    transform: scale(1);
  }
</style>

<div class="code-editor">
  <header>
    <TopBar class="">
      <div class="flex-grow text-lg mx-4 my-2 font-bold tracking-wider text-gray-700">
        All tasks
      </div>
    </TopBar>
  </header>
  <div class="flex-grow flex" style="height: calc(100% - 3rem);">
    <div class="px-4 py-2 sidebar font-bold tracking-wider">
      {#each Object.keys(components) as component}
        <div
          class="flex items-center cursor-pointer bg-gray-100 hover:shadow-2xl rounded-lg px-4
          text-sm my-3 py-2 hover:font-normal"
          style="min-width: 10rem;">
          <img src={components[component]} alt={component} title={component} class="w-6 h-6 mr-4" />
          {component}
        </div>
      {/each}
    </div>
    <div class="flex flex-col p-8 flex-grow overflow-y-auto">
      {#each ['Current release', 'Next release', 'Future releases'] as release}
        <h3 class="my-4 text-lg font-semibold tracking-wide">{release}</h3>
        <div class="flex flex-wrap -mx-4 bg-gray-400 rounded-xl shadow-inner mb-4">
          {#each [0, 1, 2, 3, 4] as i}
            <div
              class="m-4 bg-white p-6 rounded-xl flex flex-col shadow-2xl flex-1"
              style="min-width: 12rem; max-width: 20rem;">
              <span class="mb-4 text-lg">Task {i}</span>
              <span class="text-xs text-gray-600 font-bold tracking-wider uppercase mx-1 mb-3">
                Feature
              </span>
              <div class="h-2 bg-gray-500 flex">
                <div style="width: {i * 25}%; background-color: {colors[i]}" />
              </div>
            </div>
          {/each}
          <div
            class="m-4 bg-white p-6 rounded-xl flex flex-col shadow-2xl flex-1"
            style="min-width: 12rem; max-width: 20rem;">
            <span class="mb-4 text-lg">Task</span>
            <span class="text-xs text-gray-600 font-bold tracking-wider uppercase mx-1 mb-3">
              new
            </span>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
