<script>
  import { routes } from '../stores/routes';
  import { showNotifications } from '../stores/notifications';

  let brightness = 100;
</script>

<style lang="postcss">
  .notification-sidebar {
    @apply absolute bottom-0 right-0 mb-12 w-64 text-sm cursor-default
  flex flex-col;
    height: calc(100vh - 3rem);
    transition: width 250ms ease-out;
    overflow: hidden;
    box-shadow: 1px -1px 2px 2px rgba(0, 0, 0, 0.07);
    width: 25vw;
    background: rgba(26, 32, 44, 0.88);
  }

  .notification-sidebar--closed {
    @apply w-0;
  }

  #brightness-slider {
    @apply appearance-none flex-grow h-6 p-1 outline-none bg-gray-500 rounded-full;
  }

  #brightness-slider::-webkit-slider-thumb {
    @apply appearance-none w-4 h-4 bg-gray-800 rounded-full;
  }

  #brightness-slider::-moz-range-thumb {
    @apply w-4 h-4 bg-gray-800 rounded-full;
  }
</style>

<div
  class="fixed top-0 left-0 w-screen h-screen"
  class:hidden={!$showNotifications}
  on:click={() => showNotifications.set(false)} />

<div class="notification-sidebar" class:notification-sidebar--closed={!$showNotifications}>
  <div class="flex-grow" />
  <div class="flex">
    <ul class="flex mx-auto">
      {#each Array(4).fill() as tile, i}
        <li class="p-1 text-white justify-center items-center w-24 h-20">
          <div
            class="h-full flex items-center justify-center font-medium"
            style="background: rgba(255, 255, 255, 0.16); font-size: {11 + 2 * i}px"
            on:click={() => {
              document.documentElement.style.fontSize = `${12 + i}px`;
            }}>
            <span class="tracking-tighter">A</span>
          </div>
        </li>
      {/each}
    </ul>
  </div>
  <div class="p-10 flex items-center">
    <img src="/brightness.svg" alt="brightness" class="w-10 h-10 mr-4" />
    <input
      id="brightness-slider"
      type="range"
      min="25"
      max="100"
      bind:value={brightness}
      on:input={() => {
        document.body.style.filter = `brightness(${brightness}%)`;
      }} />
  </div>
</div>
