<script>
  import { ExternalLinkIcon, MailIcon, StarIcon, GitMergeIcon } from 'svelte-feather-icons';

  import TopBar from '../components/TopBar.svelte';

  let searchString;
</script>

<style lang="postcss">
  .gitweb {
    @apply flex flex-col bg-white shadow-lg h-full w-full;
    background-color: #fefefe;
  }
  .w-128 {
    width: 20rem;
  }
  .px-10 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
</style>

<div class="gitweb">
  <div class="flex" style="background-color: #24292e">
    <form
      class="flex items-center text-white py-2"
      on:submit|preventDefault={() => window.open(`https://github.com/search?q=user%3Adivyun+${searchString}`, '_blank')}>
      <img src="/gitweb.svg" alt="gitweb" class="h-8 w-8 mx-10" />
      <input
        class="px-2 rounded bg-gray-800 text-white py-1 w-128"
        type="text"
        name="search-string"
        bind:value={searchString}
        placeholder="Search Gitweb" />
    </form>
    <TopBar class="text-white flex-grow">
      <div class="flex-grow" />
    </TopBar>
  </div>
  <div class="flex-grow overflow-y-auto pt-6">
    <div class="container mx-auto p-8">
      <div class="flex px-10">
        <div class="w-1/4">
          <div class="mb-4">
            <img class="rounded-xl" alt="divyun" src="/avatar-2.svg" />
          </div>
          <div class="mb-4">
            <div class="text-3xl font-medium text-gray-900">Divyun Raje Vaid</div>
            <div class="text-xl text-gray-700 font-100">divyun</div>
          </div>
          <div class="pb-6 border-b flex">
            <a
              href="https://github.com/divyun"
              class="no-underline text-sm flex items-center"
              target="_blank">
              Github
              <ExternalLinkIcon class="ml-1 w-4 h-4" />
            </a>

            <a
              href="https://gitlab.com/divyun"
              class="ml-4 no-underline text-sm flex items-center"
              target="_blank">
              Gitlab
              <ExternalLinkIcon class="ml-1 w-4 h-4" />
            </a>
          </div>
          <div class="flex items-center text-left py-4 mb-4 border-b">
            <a href="/#" class="no-underline text-sm flex items-center">
              <MailIcon class="w-4 h-4 mr-2" />
              mail@divyun.com
            </a>
          </div>
          <div class="">
            <div class="font-medium text-gray-900">Organizations</div>
            <div class="flex items-center mt-4">
              <div class="mr-2">
                <a href="https://github.com/the-elite-1337" target="_blank">
                  <img alt="change me" class="w-12 h-12 rounded" src="/elite.png" />
                </a>
              </div>
              <div class="mr-2">
                <a href="https://github.com/sourcefuse" target="_blank">
                  <img alt="change me" class="w-12 h-12 rounded-xl" src="/sf-logo.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="w-3/4 ml-6 mt-2">
          <div
            class="flex items-center bg-gray-800 text-white border-b shadow-xl rounded-lg px-4
            tracking-wider">
            <div class="cursor-pointer px-4 py-2 bg-white text-black shadow-inner">Overview</div>
            <div class="cursor-pointer px-4 py-2 flex items-center">
              <div>Repositories</div>
              <div class="rounded-lg bg-gray-200 text-black text-xs ml-3 px-2">41</div>
            </div>
            <div class="cursor-pointer px-4 py-2 flex items-center">
              <div>Stars</div>
              <div class="rounded-lg bg-gray-200 text-black text-xs ml-3 px-2">6</div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            {#each Array(7).fill() as _, i}
              <div class="w-1/2 px-4 my-4">
                <div class="px-4 py-6 rounded bg-white shadow-xl flex flex-col text-lg">
                  <a href="/#" class="no-underline">lorem Ipsum</a>
                  <span class="text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultricies, diam
                    in accumsan ultrices, nisi elit gravida erat, id sagittis mauris lacus ut
                    tortor.
                  </span>
                  <div class="flex text-sm mt-6 text-sm">
                    <div class="mr-4 flex items-center">
                      <div class="h-3 w-3 bg-yellow-500 rounded-full mr-1" />
                      JavaScript
                    </div>
                    <div class="flex-grow" />
                    <div class="mr-4 flex items-center">
                      <StarIcon class="h-4 w-4 mr-1 text-yellow-500 fill-current" />
                      16
                    </div>
                    <div class="mr-4 flex items-center">
                      <GitMergeIcon class="h-4 w-4 mr-1" />
                      7
                    </div>
                  </div>
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
