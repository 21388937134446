<script>
  import { setContext, onMount } from 'svelte';
	import { fly } from 'svelte/transition';

  import { routes } from './stores/routes';
  import Tailwindcss from './Tailwindcss.svelte';
  import Fonts from './Fonts.svelte';
  import Desktop from './components/Desktop.svelte';
  import Welcome from './components/Welcome.svelte';

  let loggedin = false;

  setContext('appState', {
    setLogin: (val = false) => {
      loggedin = val;
    },
  });

  onMount(() => {
    if (window.location.pathname !== '/') {
      loggedin = true;
      routes.open(window.location.pathname);
    }
  });
</script>

<Tailwindcss />
<Fonts />

<Desktop />

{#if !loggedin}
  <div class="fixed top-0 left-0 w-screen h-screen z-10" transition:fly="{{ y: -720, duration: 500 }}">
    <Welcome />
  </div>
{/if}
