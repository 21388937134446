<script>
  import TopBar from '../components/TopBar.svelte';
</script>

<style lang="postcss">
  .messaging {
    @apply flex shadow-lg w-full h-full;
    color: #636363;
  }

  .listing {
    @apply flex-grow;
    background-color: rgba(255, 255, 255, 0.97);
    max-width: 25rem;
  }

  .chat {
    @apply flex-grow;
    backdrop-filter: blur(1.6rem);
    background-color: rgba(239, 238, 243, 0.97);
  }
</style>

<div class="messaging">
  <div class="listing">
    <div class="px-10 py-8 flex items-center mt-4">
      <div class="h-16 w-16 bg-gray-200 rounded-xl mr-4 text-2xl flex items-center justify-center">
        Y
      </div>

      <div class="flex flex-col">
        <span class="font-semibold text-lg">You</span>
        <span class="text-sm opacity-75 px-1">online</span>
      </div>
    </div>
    <div class="mx-10 w-100 flex bg-gray-100 rounded-full items-center mb-2">
      <span class="ml-2">
        <img src="/search.svg" alt="search" class="w-6 h-6" />
      </span>
      <input class="p-1 bg-transparent" />
    </div>

    {#each Array(2).fill() as boom}
      <div
        class="px-10 py-4 flex items-center cursor-pointer h-24 group hover:bg-blue-500
        hover:text-white">
        <img src="/avatar-2.svg" alt="" class="h-12 w-12 bg-red-100 mr-4 rounded-xl" />

        <div class="flex flex-col font-semibold flex-grow">
          <span class="text-lg">Divyun Raje Vaid</span>

          <div
            class="h-10 whitespace-normal text-xs overflow-hidden opacity-75"
            style="text-overflow: ellipsis;">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas et purus in urna
            commodo elementum. Cras purus nisi, pulvinar vel lorem ac, rutrum ultricies orci.
            Vestibulum metus risus, ultrices eu lorem eu, convallis gravida diam. Mauris ultricies
            ac lacus at ultrices. Orci varius natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Cras accumsan leo vitae mauris laoreet, eget placerat velit
            ornare. Morbi nec volutpat leo. Ut bibendum elit ac justo venenatis, eu elementum purus
            vestibulum.
          </div>
        </div>
        <div class="flex flex-col ml-4 justify-around h-full items-center">
          <div
            class="rounded-full bg-blue-600 text-white text-xs h-5 w-5 flex items-center
            justify-center font-bold group-hover:bg-white group-hover:text-blue-500">
            1
          </div>

          <span class="text-xs opacity-75">00:00</span>
        </div>
      </div>
    {/each}
  </div>

  <div class="chat relative">
    <div class="h-8">
      <TopBar>
        <div class="flex-grow" />
      </TopBar>
    </div>

    <div class="mt-4 flex items-center justify-center">
      <img src="/avatar-2.svg" alt="" class="h-16 bg-gray-300 mr-8 rounded-xl" />
      <div class="flex flex-col items-center">
        <span class="font-semibold text-lg">Divyun</span>
        <span class="text-sm opacity-75">online</span>
      </div>
    </div>

    <div class="p-8">Todo: messaging</div>
  </div>
</div>
