<script>
  import leaflet from 'leaflet';
  import { onMount } from 'svelte';
  import markerIcon from 'leaflet/dist/images/marker-icon.png';
  import markerRetinaIcon from 'leaflet/dist/images/marker-icon-2x.png';
  import markerShadowIcon from 'leaflet/dist/images/marker-shadow.png';

  import TopBar from '../components/TopBar.svelte';

  let map;
  onMount(() => {
    map = leaflet.map('map').setView([28.5514, 77.3185], 11);

    // eslint-disable-next-line no-underscore-dangle
    delete leaflet.Icon.Default.prototype._getIconUrl;

    leaflet.Icon.Default.mergeOptions({
      iconRetinaUrl: markerRetinaIcon,
      iconUrl: markerIcon,
      shadowUrl: markerShadowIcon,
    });

    const osm = leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 14,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    osm.addTo(map);

    leaflet
      .marker([28.5482, 77.2380])
      .addTo(map)
      .bindPopup('Here I am')
      .openPopup();

    leaflet
      .marker([28.5514, 77.3185])
      .addTo(map)
      .bindPopup('Here I work')
      .openPopup();
  });
</script>

<style lang="postcss" global>
  @import 'leaflet/dist/leaflet.css';
  .map {
    @apply flex flex-col shadow-lg w-full h-full;
  }
</style>

<div class="map">
  <div class="h-10">
    <TopBar>Map</TopBar>
  </div>
  <div class="" style="height: calc(100% - 2.5rem)">
    <div id="map" class="h-full w-full" />
  </div>
</div>
