<script>
  import { onMount, onDestroy } from 'svelte';
  import MediumEditor from 'medium-editor';

  let el;
  export let value = '';

  let editor;
  onMount(() => {
    editor = new MediumEditor(el, {
      placeholder: { text: 'Type in a message for Divyun' },
    });
    editor.subscribe('editableInput', function() {
      value = el.textContent;
    });
  });

  onDestroy(() => {
    editor.destroy();
  });
</script>

<style lang="postcss" global>
  @import 'medium-editor/dist/css/medium-editor.css';
  @import 'medium-editor/dist/css/themes/bootstrap.css';

  .text-editor {
    @apply p-12;
  }

  .text-editor :global(h2) {
    @apply text-2xl;
  }

  .text-editor :global(h3) {
    @apply text-xl;
  }
</style>

<div class="text-editor">
  <div bind:this={el} />
</div>
