<script>
  import { onMount } from 'svelte';
  import xterm from 'xterm';

  import TopBar from '../components/TopBar.svelte';

  let terminalEl;
  let terminal;

  onMount(() => {
    terminal = new xterm.Terminal();
    terminal.open(terminalEl);
    terminal.write('Hello from \x1B[1;3;31mdivyun\x1B[0m $ ');
  });
</script>

<style lang="postcss" global>
  @import 'xterm/css/xterm.css';
  .terminal {
    @apply flex flex-col h-full w-full;
    background-color: #1f2430ee;
    backdrop-filter: blur(7px);
  }
</style>

<div class="terminal">
  <header class="h-8">
    <TopBar class="text-white h-8">Terminal</TopBar>
  </header>

  <div bind:this={terminalEl} style="height: calc(100% - 2rem)" class="p-1" />
</div>
