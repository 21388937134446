<script>
  import { onMount, onDestroy } from 'svelte';

  import StartMenu from './StartMenu.svelte';
  import Window from './Window.svelte';
  import Notifications from './Notifications.svelte';
  import { routes, currentRoute } from '../stores/routes';
  import startMenu from '../stores/start-menu';
  import { showNotifications } from '../stores/notifications';

  let colorChangeInternal;
  let time = new Date();

  onMount(() => {
    if (window.location.pathname !== '/') {
      routes.open(window.location.pathname);
    }
    colorChangeInternal = setInterval(() => {
      time = new Date();
    }, 5000);

    currentRoute.subscribe((obj) => {
      const { route = '/', title = 'Welcome' } = obj || {};
      if (window.location.pathname === route) {
        return;
      }

      window.history.pushState(null, title, route);
    });
  });

  onDestroy(() => {
    clearInterval(colorChangeInternal);
  });

  let menuEl;
</script>

<style lang="postcss">
  main {
    @apply w-screen h-screen flex flex-col bg-black bg-cover bg-no-repeat overflow-hidden absolute z-0;
    background-image: url('/background.png');
    background-position: center bottom;
  }

  .start {
    @apply w-12 h-full flex items-center justify-center;
    transition: 250ms ease-in-out;
  }

  .start:hover {
    background: rgba(0, 0, 0, 0.25);
  }

  #desktop {
    @apply flex flex-row items-center justify-center relative;
    height: 100%;
  }

  aside {
    opacity: 0.97;
    z-index: 9999;
    backdrop-filter: blur(1.6rem);
    background: linear-gradient(
      61deg,
      rgb(172, 19, 49) 0%,
      rgb(26, 32, 44) 5.2%,
      rgb(26, 32, 44) 100%
    );
  }

  .task {
    @apply h-12 flex items-center justify-center border-blue-400;
    min-width: 3rem;
    margin: 0 0.2rem;
  }

  .task:hover {
    background: rgba(255, 255, 255, 0.07);
    width: 3.2rem;
    margin: 0 0.1rem;
  }

  .task img {
    width: 1.87rem;
    height: 1.87rem;
  }

  :global(.minimized) {
    @apply hidden;
  }

  :global(.minimized > div) {
    @apply hidden;
    bottom: 0;
  }
</style>

<svelte:head>
  <title>{$currentRoute ? $currentRoute.title : 'Desktop'} - Divyun Raje Vaid</title>
</svelte:head>

<main>
  <div
    class="absolute bottom-0 right-0 md:m-20 m-4 text-white opacity-50 text-4xl pointer-events-none
    tracking-wide"
    style="z-index: 99999; text-shadow: 0 0 0.16rem #555;">
    Work in Progress
  </div>
  <article id="desktop">
    {#each $routes as routeObj, index}
      {#if routeObj.state !== 'CLOSED'}
        <div
          style="z-index: {routeObj.order * 100}; order: {routeObj.order}"
          class:minimized={routeObj.state === 'MINIMIZED'}>
          <Window {routeObj} />
        </div>
      {/if}
    {/each}
  </article>

  <aside
    class="flex text-white"
    on:contextmenu|preventDefault|stopPropagation={(e) => {
      menuEl.classList.remove('hidden');
      menuEl.children[0].style.top = `${e.y - menuEl.children[0].offsetHeight}px`;
      menuEl.children[0].style.left = `${e.x}px`;
    }}>
    <div class="start">
      <button class="cursor-default">
        <img
          src="/start-shield.svg"
          alt="Start"
          id="start-button"
          class="p-2"
          on:click={() => startMenu.set(!$startMenu)} />
      </button>

      <StartMenu />
    </div>

    <div
      class="task text-2xl font-bold cursor-default"
      on:click={() => (document.fullscreenElement ? document.exitFullscreen() : document.documentElement.requestFullscreen())}>
      ⛶
    </div>

    <div class="flex-grow" />

    <div class="overflow-x-auto flex">
      {#each $routes as { route, state, image, title, pinned, order }}
        {#if pinned || state !== 'CLOSED'}
          <div
            class="task"
            class:border-b-2={state !== 'CLOSED'}
            class:bg-gray-800={$routes.length === order}
            on:click={() => routes[$routes.length === order ? 'minimize' : 'open'](route)}
            {title}>
            <img src={image} alt={title} {title} />
          </div>
        {/if}
      {/each}
    </div>

    <div class="flex-grow" />

    <div class="px-1 flex flex-col items-center justify-center mx-2 pointer-events-none text-sm">
      <span>
        {time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
      </span>
      <span>{time.toLocaleDateString('en-US')}</span>
    </div>
    <div class="task">
      <img
        src="/notification.svg"
        alt="Arsenal"
        id="notifications"
        on:click={() => showNotifications.set(true)} />

      <Notifications />
    </div>
    <div
      class="w-2 ml-2 h-full border-gray-600 border-l hover:bg-gray-800"
      on:click={() => routes.minimizeAll()}
      title="Show Desktop" />
  </aside>
</main>

<!-- <div
  class="hidden fixed top-0 left-0 w-screen h-screen"
  bind:this={menuEl}
  style="z-index: 99999;"
  on:click={() => {
    menuEl.classList.add('hidden');
  }}
  on:contextmenu|preventDefault|stopPropagation={(e) => {
    menuEl.classList.remove('hidden');
    menuEl.children[0].style.top = `${e.y - menuEl.children[0].offsetHeight}px`;
    menuEl.children[0].style.left = `${e.x}px`;
  }}>

  <nav id="menu" class="fixed bg-white text-black p-2">
    <ul>
      <li>New Window</li>
      <li>Close</li>
    </ul>
  </nav>
</div> -->
