<script>
  import { getContext } from 'svelte';
  import { PowerIcon } from 'svelte-feather-icons';

  import { routes } from '../stores/routes';
  import startMenu from '../stores/start-menu';

  const { setLogin } = getContext('appState');
</script>

<style lang="postcss">
  .start__menu {
    @apply absolute bottom-0 left-0 bg-gray-900 mb-12 w-64 text-sm cursor-default
  flex;
    height: 70vh;
    transition: height 160ms ease-out;
    overflow: hidden;
    box-shadow: 1px -1px 2px 2px rgba(0, 0, 0, 0.07);
    background: linear-gradient(0deg, #51172a 0%, rgb(26, 32, 44) 34%, rgb(26, 32, 44) 100%);
  }
  .start__menu__actions {
    @apply w-12 flex flex-col items-center justify-end;
  }

  .start__menu__actions > div:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .start__menu > ul {
    height: 70vh;
  }

  .start__menu--closed {
    @apply h-0;
  }
</style>

<svelte:window
  on:click={(e) => {
    if (e.target.id === 'start-button' || !$startMenu) {
      return;
    }
    startMenu.set(false);
  }} />

<div class="start__menu" class:start__menu--closed={!$startMenu}>
  <div class="start__menu__actions">
    <div class="p-2 w-12 h-12">
      <img
        src="/avatar-lines.svg"
        alt="Divyun Raje Vaid"
        class="rounded-full"
        style="background-color: rgba(255, 255, 255, 0.079)" />
    </div>
    <div class="w-12 h-12" on:click={() => setLogin(false)}>
      <PowerIcon class="p-3 w-12 h-12" />
    </div>
  </div>
  <ul class="flex-grow overflow-y-auto">
    {#each $routes as { route, state, image, title }}
      <li>
        <a
          class="flex h-12 p-2 items-center hover:bg-gray-800 cursor-default"
          class:my-4={!$startMenu}
          href={route}
          style="transition: margin 340ms ease-in-out;"
          {title}
          on:click|preventDefault={() => {
            routes.open(route);
          }}>
          <img src={image} alt={title} {title} class="mr-5 h-6 w-6" />
          <span class="flex-grow">{title}</span>
        </a>
      </li>
    {/each}
  </ul>
</div>
